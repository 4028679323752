<template>
  <div>
    <NuxtLayout>
      <template v-if="!canShowContent">
        <div>ERREUR</div>
      </template>
      <template v-else>
        <div class="render-content">
          <CustomRenderContent :content="content" model="page" />
          <!--RenderContent
            model="page"
            :content="content"
            :api-key="$env.builder.apiKey"
            :customComponents="getCustomComponents"
          /-->
        </div>
      </template>
    </NuxtLayout>
  </div>
</template>
<script>
import { isPreviewing } from '@builder_sdk';
import { REGISTERED_COMPONENTS } from '@/init-builder.ts';
import { useAsyncData } from 'nuxt/app';
import CustomRenderContent from "@/components/organisms/RenderContent/CustomRenderContent.vue";

export default defineNuxtComponent({
  components: {CustomRenderContent},
  props: {
    error: Object,
  },
  async setup() {
    const error = useError();
    if (error && error.value && error.value.statusCode === 404) {
      error.value.message = '[script]: Oops! Page not found 😔';
    }

    let title;
    if (error.value.statusCode === 404) title = 'Page introuvable';
    else if (error.value.statusCode === 410) title = 'Cet événement est trop ancien';
    else title = 'Une erreur s\'est produite'

    useHead({ title  });

    const nuxtApp = useNuxtApp();
    const data = {};

    try {
      const route = useRoute();
      // const params = {};
      
      const previewing = route.query.previewing;

      const { data: content } = await useAsyncData(() => nuxtApp.$builder.getOne('page', {
        userAttributes: {
          urlPath: [404, 410].includes(error.value.statusCode) ? `/${error.value.statusCode}` : '/error',
        },
        includeUnpublished: previewing == 'true'
      }));
      
      data.statusCode = error.value.statusCode;
      data.content = content.value;
      data.canShowContent = !!data.content || isPreviewing() || previewing;
    }
    catch (err) {
      console.log(err);
    }

    if (!data.content && !data.canShowContent) {
      console.log('no content', isPreviewing());

      // AFFICHER UN COMPOSANT PAR DEFAUT
    }
    return data;
  },
  computed: {
    apiKey() {
      return this.$env.builder.apiKey;
    },
    getCustomComponents() {
      return REGISTERED_COMPONENTS;
    },
  },
  async mounted() {
    // Re-run this check on the client in case of SSR
    this.canShowContent = this.content || isPreviewing();

    this.$store.dispatch("analytics/track", {
      event: "Makesense Chiche - Error",
      data: {
        type: this.statusCode,
        page: this.$router.path
      }
    });
  },
})
</script>
<style lang="scss" scoped>

</style>
